import React from 'react';
import { Link } from 'react-router-dom';
import {Container} from 'react-bootstrap';

const SingleApplication = () => {
  return (
    <Container fluid>
      <h2>Single Application</h2>
    </Container>
  );
};

export default SingleApplication;
