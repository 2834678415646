import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { Container, Row, Col, Button,Card,Badge ,Form,ListGroup,Modal,Pagination,Spinner,Alert} from 'react-bootstrap';
import REACT_API_BASE_URL from "../config.js";
const UserManagement = () => {
  const [users, setUsers] = useState([]);
 // Fetch users from API or server
  
const fetchUsers = async () => {
try {
  const response = await fetch(REACT_API_BASE_URL+'/users', {
    method: 'GET', // Specify GET method
    headers: {
      'Content-Type': 'application/json', // Adjust if necessary
      'Authorization': `Bearer `+localStorage.getItem('authToken')
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch users');
  }

  const data = await response.json();
  setUsers(data); // Update the state with the fetched users
} catch (error) {
  console.error('Error fetching users:', error);
}
};

useEffect(() => {
  fetchUsers();
}, []);


  const handleDeleteUser = (userId) => {
    // Logic for deleting a user
    console.log(`Delete user with ID: ${userId}`);
    // Add API call to delete user
  };

  return (
    <div className="container mt-4">
      <h2>User Management</h2>
      <Col md={12}>
          <Button as={Link} to="/dashboard/signup">Add New User</ Button>
        </Col>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDeleteUser(user.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserManagement;
