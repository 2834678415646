import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, Container, Row, Col, Navbar, Nav } from 'react-bootstrap';

const Team = () => {
  return (
    <Container fluid>
      <h2>Applications</h2>
    </Container>
  );
};

export default Team;
