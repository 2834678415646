import React, { useContext,useEffect } from 'react';
import { Link,useNavigate  } from 'react-router-dom';
import { Navbar, Container, Nav, Button,Dropdown,DropdownButton } from 'react-bootstrap';
import AuthContext from '../helpers/context/AuthContext'; 
import logo from "../ce.svg";
import logo2 from '../ssel.png';

function Topbar() {
  const { isAuthenticated,user,logout } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    console.log('Auth status updated:', isAuthenticated, 'User:', user);
  }, [isAuthenticated, user]);
  const handleLogout = (e) => {
    if (e) e.preventDefault();
    //console.log('logout');
    logout();
          navigate('/'); // Replace '/login' with your desired path
  }
  return (
   <Navbar expand="lg" className="topbar">
      <Container fluid>
        <Navbar.Brand href="#home" className="mx-auto mx-lg-0">
          <img
            src={logo2}
            alt="Smart IoT"
            width="300"
            height="85"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>

        {isAuthenticated && <Navbar.Toggle aria-controls="basic-navbar-nav" />}

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {isAuthenticated ? (
              <>
                <span className="mx-auto p-2">
                  {user?.fullName || user?.username}
                </span>
                <Button variant="outline-danger" onClick={handleLogout}>
                  Logout
                </Button>
              </>
            ) :null}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Topbar;
